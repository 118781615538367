// Import dependencies
import React from 'react'
import { Link } from 'gatsby'

// Bootstrap components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Import styles
import 'bootstrap/dist/css/bootstrap.css'
import './header.css'
import '../../pages/styles/home.css'

// Import icons
import Logo from '../../assets/icons/textual-logo.svg'
import LogoGreen from '../../assets/icons/textual-logo-green.svg'
import Menu from '../../assets/icons/menu.svg'
import MenuGreen from '../../assets/icons/menu-green.svg'
import Cross from '../../assets/icons/cross.svg'
import CrossGreen from '../../assets/icons/cross-green.svg'


export default class ContactForm extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = { toggled: '' };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.state.toggled === '' ?
            this.setState({ toggled: ' header__menu-toggled' })
            :
            this.setState({ toggled: '' });
    }

    render() {
        return (
            <div className={
                this.props.toggle ?
                    this.props.toggleValue ?
                        'header__container header__container-hidden header__container-hidden-toggle'
                        :
                        'header__container header__container-hidden'
                    :
                    'header__container'
            }>
                <div className='header__container-inner'>
                    <Link to='/' aria-label="Logo">
                        <div className='header__logo-container'>
                            {
                                !this.props.recycled ?
                                    <Logo alt='spiroplastic-logo' className='header__logo' />
                                    :
                                    <LogoGreen alt='spiroplastic-logo' className='header__logo' />
                            }
                        </div>
                    </Link>
                    <div className='header__links-container'>
                        <Link to='/' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>HOME</Link>
                        <Link to='/recycled' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>RECYCLED</Link>
                        <Link to='/history' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>HISTORY</Link>
                        <Link to='/about' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>ABOUT</Link>
                        <Link to='/products' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>PRODUCTS</Link>
                        <Link to='/contact' className={this.props.recycled ? 'header__link header__link-green' : 'header__link'}>CONTACT</Link>
                    </div>

                    {/* Burger button */}
                    <div className='header__burger-container' onClick={this.toggleMenu}>
                        {
                            this.props.recycled ?
                            <MenuGreen className='header__burger-menu' />
                            :
                            <Menu className='header__burger-menu' />
                        }
                    </div>

                    <div className={'header__mobile-menu-container' + this.state.toggled}>
                        {/* Burger button */}
                        <div className='header__burger-container' onClick={this.toggleMenu}>
                            {
                                this.props.recycled ?
                                <CrossGreen className='header__burger-menu' />
                                :
                                <Cross className='header__burger-menu' />
                            }
                        </div>

                        <div style={{ width: '100%', height: 20 }} />
                        <Container fluid style={{ margin: 0, padding: 0 }}>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>HOME</Link>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/recycled' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>RECYCLED</Link>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/history' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>HISTORY</Link>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/about' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>ABOUT</Link>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/products' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>PRODUCTS</Link>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='header__column-padding text-center'>
                                    <Link to='/contact' className='header__mobile-title header__mobile-button' style={this.props.recycled ? {color: '#01D275', fontSize: 16} : {color: '#0068A5', fontSize: 16}}>CONTACT</Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
