// Import dependencies
import React from "react"

import {
  TitleText,
  PageContainer,
  ParagraphText,
  Margins,
  Gap150,
} from "../styled/UILibrary"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Import styles
import "./footer.css"

const Footer = () => (
  <PageContainer margin={"0"}>
    <Margins>
      <Gap150 />

      <Container fluid style={{ margin: 0, padding: 0 }}>
        <Row>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              Home
            </TitleText>
            <div className="footer__links-container">
              <a href="/#who-we-are" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Who we are
                </ParagraphText>
              </a>
              <a href="/#our-values" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Our values
                </ParagraphText>
              </a>
            </div>
          </Col>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              Recycled
            </TitleText>
            <div className="footer__links-container">
              <a href="/recycled/#green" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Green
                </ParagraphText>
              </a>
              <a href="/recycled/#process" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Process
                </ParagraphText>
              </a>
              <a href="/recycled/#step-1" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Step 1
                </ParagraphText>
              </a>
              <a href="/recycled/#step-2" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Step 2
                </ParagraphText>
              </a>
              <a href="/recycled/#step-3" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Step 3
                </ParagraphText>
              </a>
            </div>
          </Col>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              History
            </TitleText>
            <div className="footer__links-container">
              <a href="/history/#our-story" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Our story
                </ParagraphText>
              </a>
              <a href="/history/#1930" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  1930
                </ParagraphText>
              </a>
              <a href="/history/#1950" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  1950
                </ParagraphText>
              </a>
              <a href="/history/#1973" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  1973
                </ParagraphText>
              </a>
              <a href="/history/#1996" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  1996
                </ParagraphText>
              </a>
            </div>
          </Col>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              About
            </TitleText>
            <div className="footer__links-container">
              <a href="/about/" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  About us
                </ParagraphText>
              </a>
              <a href="/about/#our-history" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Our history
                </ParagraphText>
              </a>
              <a href="/about/#philanthropy" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Philanthropy
                </ParagraphText>
              </a>
              <a href="/about/#modern-day" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Modern day
                </ParagraphText>
              </a>
            </div>
          </Col>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              Products
            </TitleText>
            <div className="footer__links-container">
              <a href="/products/" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Our products
                </ParagraphText>
              </a>
              <a href="/products/#benefits" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Benefits
                </ParagraphText>
              </a>
              <a href="/products/#uses" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Uses
                </ParagraphText>
              </a>
              <a href="/products/#product-gallery" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Product specifications
                </ParagraphText>
              </a>
            </div>
          </Col>
          <Col xl={2} lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 50 }}>
            <TitleText minSize={16} maxSize={22}>
              Contact
            </TitleText>
            <div className="footer__links-container">
              <a href="/contact/" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Offices
                </ParagraphText>
              </a>
              <a href="/contact/#reach-us" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Reach us
                </ParagraphText>
              </a>
              <a href="/contact/#branches" aria-label="Anchor">
                <ParagraphText minSize={18} maxSize={20} style={{ height: 48 }}>
                  Branches
                </ParagraphText>
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="footer__bottom-bar">
        <div className="footer__bottom-bar-left">
          <ParagraphText minSize={12} maxSize={16}>
            © Spiroplastic S.A.
          </ParagraphText>
        </div>
        {/* <div className="footer__rootnode-container">
          <ParagraphText minSize={12} maxSize={16}>
            Designed & developed by&nbsp;
          </ParagraphText>
          <a href="https://rootnode.io/">
            <ParagraphText minSize={12} maxSize={16}>
              rootnode
            </ParagraphText>
          </a>
        </div> */}
      </div>

      <Gap150 />
    </Margins>
  </PageContainer>
)

export default Footer
