// Import dependencies
import styled from 'styled-components'


export const PageContainer = styled.div`
    width: 100%;
    height: auto;
    max-width: 1600px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    ${ props => props.margin ? 'margin-top: ' + props.margin : ''};
`;
export const Margins = styled.div`
    width: 80%;
    height: auto;
`;

export const Box = styled.div`
    border: #E5E5E5 solid 1px;
    padding: ${ props => props.padding};
    ${ props => props.rounded ? 'border-radius: 4px' : ''};
`;

export const TitleText = styled.h1`
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    font-size: calc(${ props => props.minSize}px + (${props => props.maxSize} - ${props => props.minSize}) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(${ props => props.minSize + 15}px + (${props => props.maxSize + 15} - ${props => props.minSize + 15}) * ((100vw - 300px) / (1600 - 300)));
    letter-spacing: 2px;
    color: #080738;
    margin: 0;
    padding: 0;
    font-display: swap;
    ${ props => props.gray ? 'color: #929292' : ''};
    ${ props => props.white ? 'color: #FFFFFF' : ''};
    ${ props => props.blue ? 'color: #0068A5' : ''};
    ${ props => props.nospacing ? 'letter-spacing: 0' : ''};
    ${ props => props.nolineheight ? 'line-height: normal' : ''};
    ${ props => props.underlined ? 'text-decoration: underline' : ''};
    ${ props => props.shadow ? 'text-shadow: 0 1px 6px rgba(8,7,56,.6)' : ''};
    ${ props => props.center ? 'text-align: center' : ''};
    ${ props => props.lineheight ? 'line-height: '+props.lineheight : ''};
`;
export const SubTitleText = styled.h2`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: calc(${ props => props.minSize}px + (${props => props.maxSize} - ${props => props.minSize}) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(${ props => props.minSize + 6}px + (${props => props.maxSize + 6} - ${props => props.minSize + 6}) * ((100vw - 300px) / (1600 - 300)));
    letter-spacing: 0;
    color: #0072BC;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-display: swap;
    ${ props => props.green ? 'color: #01D275' : ''};
    ${ props => props.shadow ? 'text-shadow: 0 1px 6px rgba(8,7,56,.6);' : ''};
`;
export const ParagraphText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: calc(${ props => props.minSize}px + (${props => props.maxSize} - ${props => props.minSize}) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(${ props => props.minSize + 8}px + (${props => props.maxSize + 8} - ${props => props.minSize + 8}) * ((100vw - 300px) / (1600 - 300)));
    color:${ props => props.white ? '#ffffff' : '#080738'};
    margin: 0;
    padding: 0;
    font-display: swap;
    ${ props => props.shadow ? 'text-shadow: 0 1px 6px rgba(8,7,56,.6);' : ''};
    ${ props => props.nolineheight ? 'line-height: normal' : ''};
    ${ props => props.link ? 'color: #0068A5' : ''};
    ${ props => props.green ? 'color: #01D275' : ''};
    ${ props => props.bold ? 'font-weight: 600' : ''};
`;

export const Gap200 = styled.div`
    height: 20vh;
    background-color: transparent;
`;
export const Gap150 = styled.div`
    height: 15vh;
    background-color: transparent;
`;
export const Gap60 = styled.div`
    height: 5.9vh;
    background-color: transparent;
`;
